// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10558(724a668551)-C17/09/2024-09:50:55-B17/09/2024-09:57:40' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10558(724a668551)-C17/09/2024-09:50:55-B17/09/2024-09:57:40",
  branch: "master",
  latestTag: "6.1",
  revCount: "10558",
  shortHash: "724a668551",
  longHash: "724a668551c33c2fb7575f3d3563002501af42ff",
  dateCommit: "17/09/2024-09:50:55",
  dateBuild: "17/09/2024-09:57:40",
  buildType: "Ansible",
  } ;
